nav {
	background-color: #F7F8F9;
	padding: 1.8rem;
	border-bottom: 1px solid #d9d9d9;
}
nav ul {
	margin: 0 auto;
	padding: 0;
    display: flex;
    flex-direction: column;
}

nav li {
	text-decoration: none;
	list-style-type: none;
    margin: 0 auto;
}

.circle-button {
	border: none;
	background-color: transparent;
	padding: 0;
	/* cursor: pointer; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* width: 60px; */
}

.circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: white;
	color: black;
	opacity: 0.6;
	font-size: 1rem;
	font-weight: 500;
	border: 2px solid #d9d9d9;
}

.label {
	font-size: 0.9rem;
	margin-top: 8px;
	text-align: center;
}
.nav-line hr {
	margin-top: 1rem;
    margin-bottom: 1.2rem;
	border: none;
	background-color: #d9d9d9;
	height: 1px;
	width: 100%;
}

.active .circle{
	background-color: #0077B6;
	color: white;
	opacity: 1;
	border-color: #0077B6;
}
.active .label {
	color: #0077B6;
	font-weight: 500;
}

.completed .circle {
	background-color: white;
	opacity: 1;
	color: #70A37F;
	border-color: #70A37F;
	cursor: pointer;
}

.circle {
	transition: background-color ease 0.25s, color ease 0.25s, border-color ease 0.25s;
}
.label {
	transition: color ease 0.25s;
}

@media (min-width: 576px) {
	nav ul {
		width: 70%;
		justify-content: space-around;
        flex-direction: row;
	}

	.nav-line hr {
		width: 5.2rem;
        margin-top: 1rem;
    }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}
