header {
	background-color: #e7eaed;
	border-bottom: 1px solid #d9d9d9;
	padding: 0.5rem;
}

header img {
	width: 7rem;
	height: 1.5rem;
}

h1 {
	display: none;
	font-size: 20px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: center;
}

.header-center {
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.language-select {
	background-color: transparent;
	border: none;
	padding: 0.4em;
	font-size: inherit;
	cursor: pointer;
}

select:active, select:focus {
	outline: none;
	border: none;
}


@media (min-width: 400px) {
	h1 {
		display: block;
		font-size: 1rem;
	}
}

@media (min-width: 576px) {
	/* Styles for mobile devices */
	h1 {
		font-size: 1.5rem;
	}
}

@media (min-width: 768px) {
	.header-center {
		width: 70%;
	}
}

@media (min-width: 1024px) {
	.header-center {
		width: 60%;
	}
}
