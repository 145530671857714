.btn-box {
	margin-top: 3.5rem;
	display: flex;
	justify-content: space-between;
	padding-bottom: 5rem;
}

.btn-box.no-back-btn::before {
	content: "";
	display: inline-block;
	width: 8.6rem;
	padding: 10px 24px 12px 24px;
}

.btn-back {
	background-color: white;
	color: #0077b6;
	padding: 10px 24px 12px 24px;
	border-radius: 4px;
	font-family: "Roboto";
	font-size: 1rem;
	border: 1px solid #0077b6;
	width: 8.6rem;
	font-weight: 500;
}

.btn-back:hover {
	cursor: pointer;
	background-color: #e5e5e5;
}

.btn-next {
	background-color: #0077b6;
	color: white;
	padding: 10px 24px 12px 24px;
	border-radius: 4px;
	font-family: "Roboto";
	font-size: 1rem;
	border: none;
	width: 14rem;
	font-weight: 500;
}

.btn-next:hover {
	cursor: pointer;
	background-color: #006297;
}


@media (min-width: 768px) {

}
