.main-container {
    display: flex;
    height: 85%;
    justify-content: center;
}

.table-container {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.small-table-container {
    width: 30%;
}

.small-table-container .index-cell {
    width: 20px;
}

.header-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #7e7e7e;
}

.header-cell,
.index-cell {
    text-align: center;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #D9D9D9;
}

.header-cell {
    color: rgba(0, 0, 0, 0.60);
    width: 1.6%;
    border-right: 1px solid #D9D9D9;
    background-color: #F5F5F5;
}

.index-cell {
    color: rgba(0, 0, 0, 0.60);
    width: 1.6%;
    border-right: 2px solid #7E7E7EFF;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    background-color: #F5F5F5;
}

.invisible-cell {
    width: 27px !important;
    border-top: none;
    background-color: transparent;
}

.column-cell {
    width: 24px;
}

.table-row {
    display: flex;
    height: 31px !important;
}

.table-row:last-child {
    border-bottom: 1px solid black;
}

.cell {
    flex: 1;
    border-right: 2px solid #7E7E7EFF;
}

.cell-group {
    border: none;
    border-right: 1px solid black;
    width: 50%;
}

.select-container {
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    margin-left: 10px;
    height: 100%;
    margin-top: 35px;
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 45px;
}

.select-wrapper {
    display: flex;
    align-items: center;
}

.select {
    width: 70px;
    height: 28px;
    font-size: 13px;
    padding: 0;
    margin: auto;
}

.select-row {
    height: 31px !important;
}

.checkbox-row {
    height: 31px !important;
}

