.primary-info {
	background-color: #f7f8f9;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	display: flex;
	flex-wrap: wrap;
	padding: 8px 12px;
	gap: 8px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 2rem;
	margin-top: 0.5rem;
	color: #000000de;
}

.small-info {
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.75rem;
	font-style: italic;
}

.border-box {
	position: relative;
	border: 1px solid #009aff;
	border-radius: 6px;
	padding: 3rem 6.5rem;
	width: 100%;
	margin-left: -6.5rem;
}

.border-box-label {
	position: absolute;
	top: -1.2rem;
	margin-left: -1rem;
	background-color: white;
	padding: 0.5rem 1rem;
	border: 1px solid #009aff;
	border-radius: 6px;
}

.drum-configuration-link {
	margin-top: -2rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 8px 32px 8px 32px;
	gap: 16px;
	border-radius: 4px;
	border: 1px solid #0077b6;
	margin-bottom: 2rem;
	max-width: 210px;
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 19px;
}

.drum-configuration-link:hover {
	border-radius: 4px;
	border: 1px solid #0077b6;
	background: #0077b6;
	color: white;
}

.drum-configuration-link:hover svg {
	filter: invert(96%) sepia(100%) saturate(12%) hue-rotate(237deg) brightness(103%) contrast(103%);
}

.drum-configuration {
	margin-top: 3.5rem;
	margin-bottom: 0;
}

.file-input {
	display: none;
}

.close-btn {
	background: none;
	color: #b2b2b2;
	border: none;
	padding: 0;
	font-size: 24px;
	cursor: pointer;
	transition: transform 0.3s ease;
}

.close-btn:hover {
	transform: scale(1.2);
}

.checkbox-input-box {
	display: flex;
	align-items: center;
	width: 50%;
	margin-bottom: 0.5rem;
}

.checkbox-input-box input[type="checkbox"] {
	padding: 0.6rem;
	margin-right: 0.8rem;
}

.checkbox-input-box label {
	cursor: pointer;
	color: rgb(0, 0, 0);
	margin: 0;
	/* margin-left: 0.5rem; */
	width: 8.28rem;
	font-size: 1.15rem;
}

.checkbox-input-box input {
	cursor: pointer;
}

.input-ral {
	margin-left: 0.7rem;
	margin-right: 2.5rem;
	padding: 0.6rem 1.1rem;
	width: 8.8rem;
	color: black;
}

.input-ral::placeholder {
	color: black;
}

.overprint-info p {
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.6rem;
	width: 200px;

	font-family: Roboto;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
}

.machines-badges {
	display: flex;
	align-items: center;
	cursor: pointer;
	gap: 16px;
	margin-top: 42px;
	flex-wrap: wrap;
}

.machine-badge {
	display: flex;
	align-items: center;
	gap: 16px;
	cursor: pointer;
	background-color: transparent;
	color: #0077b6;
	border: 1px solid #0077b6;
	padding: 0 16px;
	height: 30px;
	border-radius: 25px;

}

.machine-badge.active {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #0077b6;
	color: white;
	padding: 0 16px;
	height: 30px;
	border-radius: 25px;
}

.remove-machine-button {
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
}

.add-machine-button {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.model-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: flex-start;
  }

  .radio-with-image {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 150px;
	cursor: pointer;
	padding: 8px;
	border: 1px solid transparent;
	border-radius: 4px;
	box-sizing: border-box;
	transition: border-color 0.3s, background-color 0.3s;
  }

  .radio-with-image:hover {
	border-color: #0077b6;
  }

  .radio-with-image.selected {
	border-color: #0077b6;
	background-color: #e0f0ff;
  }

  .radio-with-image img {
	width: 100%;
	height: 100px;
	object-fit: contain;
  }

  .model-label {
	text-align: center;
  }

  .error-message {
	color: #AF3732;
	margin-top: 8px;
  }

  .radio-input-box {
	display: flex;
	flex-direction: column;
	gap: 1rem;
  }
