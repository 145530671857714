.main-card {
	background-color: white;
	width: 100%;
	margin: 0 auto;
	min-height: 100vh;
}

main {
	width: 90%;
	margin: 0 auto;
}

h2 {
	position: relative;
	padding-bottom: 8px;
	font-size: 20px;
	font-weight: 500;
	display: inline-block;
	text-transform: uppercase;
	line-height: 23px;
	margin-bottom: 3rem;
	margin-top: 5rem;
}

h2:first-child {
	margin-top: 3rem;
}

h2:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-bottom: 2px solid #0077b6;
}

.input-box {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
}

input,
select {
	padding: 0.8rem 1.1rem;
	border-radius: 4px;
	margin: 0;
	border: 1px solid #d9d9d9;
	background-color: white;
}

select {
	padding-right: 20px;
	background-position: right center;
	background-repeat: no-repeat;
	cursor: pointer;
}

option {
	padding-right: 20px !important;
}

select:focus {
	border: 1px solid #d9d9d9 !important;
}

input::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 16px;
	color: #a0a0a0;
	outline: none;
}

input:focus,
textarea:focus {
	box-shadow: 0 0 3px 0px #0076b6be;
	border: 1px solid #0081c775;
	outline: none;
	transition: box-shadow ease 0.25s, border-color ease 0.25s;
}

input[type="radio"] {
	appearance: none;
	background-color: #fff;
	margin: 0 0.5rem 0 0;
	font: inherit;
	color: rgba(0, 0, 0, 0.87);
	width: 1rem;
	height: 1rem;
	padding: 0;
	border: 0.125rem solid rgba(0, 0, 0, 0.87);
	border-radius: 50%;
	display: grid;
	place-content: center;
}

input[type="radio"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	border-radius: 50%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em #0077b6;
}

input[type="radio"]:checked {
	border-color: #0077b6;
}

input[type="radio"]:checked::before {
	transform: scale(1);
}

input[type="radio"]:focus {
	box-shadow: 0 0 5px -1px #0077b6;
}

input[type="checkbox"] {
	appearance: none;
	background-color: #fff;
	margin: 0 0.5rem 0 0;
	font: inherit;
	color: currentColor;
	width: 1em;
	height: 1em;
	padding: 0;
	border: 0.125rem solid rgb(87, 87, 87);
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;
	cursor: pointer;
}

input[type="checkbox"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em #0077b6;
}

input[type="checkbox"]:checked::before {
	transform: scale(1);
	transform-origin: bottom left;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked {
	border: 0.125rem solid #0077b6;
}

label {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.6);
	margin-bottom: 0.5rem;
}

.side-text {
	margin-left: 10px;
	font-size: 1rem;
	font-style: italic;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #00000099;
}

.file-label {
	color: #d9d9d9;
	opacity: 0.5;
	text-align: center;
	display: inline-flex;
	padding: 0.25rem 1.5rem;
	font-size: 0.875rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #d9d9d9;
	background: #fff;
}

.file-label.enabled:hover {
	background-color: #f1f1f1;
}

.file-label.enabled {
	color: black;
	opacity: 1;
	cursor: pointer;
}

.file-input {
	cursor: not-allowed;
}

.file-input.enabled {
	cursor: pointer;
}

.file-label.disabled {
	opacity: 0.5;
}

.select-phone-number input {
	border: 1px solid #d9d9d9 !important;
	border-radius: 4px !important;
	padding-top: 0.8rem !important;
	padding-bottom: 0.8rem !important;
	font-family: "Roboto";
	font-size: 1rem !important;
	line-height: 16px !important;
	color: rgba(0, 0, 0, 0.87) !important;
	width: 15rem !important;
}

.select-phone-number input:focus {
	box-shadow: 0 0 3px 0px #0076b6be !important;
	border: 1px solid #0081c775 !important;
	outline: none !important;
	transition: box-shadow ease 0.25s, border-color ease 0.25s !important;
}

.error input {
	border-color: #af3732 !important;
}

.error input:focus {
	box-shadow: 0 0 3px 0px #af3732 !important;
	border-color: #af3732 !important;
}

.error-message {
	color: #af3732;
	font-size: 1rem;
	margin-top: 0.5rem;
}

@media (min-width: 576px) {
	/* Styles for mobile devices */
}

@media (min-width: 768px) {
	main {
		width: 70%;
	}

	.main-card {
		width: 70%;
	}
}

@media (min-width: 1024px) {
	main {
		width: 60%;
	}

	.main-card {
		width: 60%;
	}
}

.radio-input-box {
	display: flex;
}

.radio-input-box label {
	color: rgb(0, 0, 0);
	font-size: 1.15rem;
}

label {
	color: rgba(0, 0, 0, 0.6);
	padding-right: 1.4rem;
	margin-bottom: 1.2rem;
}

.font-bold-700 {
	font-weight: 700;
}

.font-bold {
	font-weight: 600;
}
