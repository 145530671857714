.radio-label span,
.radio-image-label span {
	font-size: 1.15rem;
}

.radio-image-label {
	margin-right: 36px !important;
}

.radio-label {
	color: #000000de;
    margin-right: 28px !important;
}

.radio-with-image {
	display: flex;
	flex-direction: column;
	align-items: self-start;
	justify-content: center;
}

.image-radio-input {
	margin-bottom: 1rem;
}

img {
	cursor: pointer;
	margin-bottom: 0.3rem;
	height: 120px;
}

.radio-label {
	margin-bottom: 0px;
}

.radio-group .input-box {
	margin-bottom: 3rem;
}

.conditional-radio-group .input-box {
	margin-bottom: 3rem;
}