.summary-page {
    padding: 2rem;
}

.section {
    margin-bottom: 1.5rem;
}

.section h3 {
    margin-bottom: 12px;
}

.summary-row {
    display: grid;
    grid-template-columns: 200px auto;
    gap: 16px;
    margin-bottom: 8px;
    align-items: center;
}

.summary-label {
    color: #000000;
    opacity: 0.6;
}

.summary-value {
    color: #000000;
}

.generate-pdf-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.additional-comment label {
    color: #000000;
    margin-top: 64px;
}

.contractSubmit {
    font-weight: 600;
    font-size: 14px;
}

.rules-box {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 5px;
}

.rules-box input[type="checkbox"] {
    margin-top: 2px;
    margin-right: 10px;
    flex-shrink: 0;
}

.rules-box label {
    cursor: pointer;
    flex: 1;
    display: inline;
    word-break: normal;
    white-space: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
}

.rules-box a {
    color: #0077b6;
    text-decoration: none;
    display: inline;
}

.rules-box a:hover {
    text-decoration: underline;
}

.rules .error-message {
    margin-bottom: 15px !important;
}
