.input-with-label {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	background-color: #f7f8f9;
	width: 19rem;
}

.input-with-label input {
	border: none;
	width: 45%;
	padding-right: 0;
}

.input-with-label span {
	width: 50%;
	font-size: 0.8rem;
	font-weight: 400;
	opacity: 0.6;
	margin: 0 auto;
	padding: 1rem;
}

.calendar-box {
	margin-bottom: 2rem;
	display: flex;
}

textarea {
	height: 12rem;
	border-radius: 4px;
	border-color: #d9d9d9;
	resize: none;
	/* max-width: 560px; */
}

.contract-link {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 8px 32px 8px 32px;
	gap: 16px;
	border-radius: 4px;
	border: 1px solid #0077b6;
	margin-top: 4rem;
	margin-bottom: 1.5rem;
	max-width: 140px;
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 19px;
}

.contract-link:hover {
	border-radius: 4px;
	border: 1px solid #0077b6;
	background: #0077b6;
	color: white;
}

.contract-link:hover svg {
	filter: invert(96%) sepia(100%) saturate(12%) hue-rotate(237deg) brightness(103%) contrast(103%);
}

.rules a {
	color: inherit;
	text-decoration: none;
}

.rules-box {
	display: flex;
	align-items: center;
}

.rules-box label {
	cursor: pointer;
	color: #000000de;
	font-size: 1.2rem;
	margin: 0;
}

.rules-box input[type="checkbox"] {
	padding: 0.6rem;
}

.drum-configuration {
	margin-top: 3.5rem;
	margin-bottom: 0;
}

.installation-details {
	margin-bottom: 5rem;
}

.range-calendar-box {
	display: flex;
	gap: 20px;
	margin-bottom: 2rem;
}

.range-calendar-box .side-text {
	margin: 0;
}

.primary-info {
	background-color: #f7f8f9;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	display: flex;
	flex-wrap: nowrap !important;
	padding: 8px 12px;
	gap: 8px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 2rem;
	margin-top: 0.5rem;
	color: #000000de;
}

