.checkboxes-group {
  margin-bottom: 1rem;
}

.checkboxes-group-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.checkboxes-container {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.checkbox-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.checkbox-item label {
  margin-bottom: 0;
}
.checkbox-description {
  color: #666;
  font-size: 0.875rem;
  max-width: 152px;
}
